import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
    { path: '', component: DashboardComponent },
    { path: 'cartillas', loadChildren: () => import('./cartillas/cartillas.module').then(m => m.CartillasModule) },
    { path: 'lotes', loadChildren: () => import('./lotes/lotes.module').then(m => m.LotesModule) },
    { path: 'empresas', loadChildren: () => import('./empresas/empresas.module').then(m => m.EmpresasModule) },
    { path: 'obras', loadChildren: () => import('./obras/obras.module').then(m => m.ObrasModule) },
    { path: 'usuarios', loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosModule) },
    { path: 'figuras', loadChildren: () => import('./figuras/figuras.module').then(m => m.FigurasModule) },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
