import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.HOME.TEXT',
        icon: 'ri-home-line',
        link: ''
    }, {
        id: 2,
        label: 'MENUITEMS.EMPRESAS.TEXT',
        icon: 'ri-building-line',
        link: '/empresas'
    }, {
        id: 3,
        label: 'MENUITEMS.OBRAS.TEXT',
        icon: 'ri-government-line',
        link: '/obras'
    }, {
        id: 4,
        label: 'MENUITEMS.CARTILLAS.TEXT',
        icon: 'ri-book-open-line',
        link: '/cartillas'
    }, {
        id: 5,
        label: 'MENUITEMS.LOTES.TEXT',
        icon: 'ri-stack-line',
        link: '/lotes'
    }, {
        id: 6,
        label: 'MENUITEMS.USUARIOS.TEXT',
        icon: 'ri-user-line',
        link: '/usuarios'
    }, {
        id: 6,
        label: 'MENUITEMS.FIGURAS.TEXT',
        icon: 'ri-user-line',
        link: '/figuras'
    }
];

